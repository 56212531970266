<script setup lang="ts">
import type { VebraPrice } from '@/types'

defineProps({
  database: {
    type: String as PropType<string>,
    required: true,
  },
  price: {
    type: Object as PropType<VebraPrice>,
    required: true,
  },
  dark: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { format } = usePrice()
</script>

<template>
  <div :class="dark ? 'text-gray-100' : 'text-gray-700'">
    <div
      class="text-xs mr-1.5 leading-4"
    >
      {{ price.qualifier }}:
    </div>
    <span class="text-sm font-semibold">
      {{ format(price) }}
    </span>
    <span
      v-if="database === '2'"
      class="text-sm ml-1.5"
    >
      PCM
    </span>
  </div>
</template>
