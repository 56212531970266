<script setup lang="ts">
defineProps({
  property: {
    type: Object as PropType<Sanity.Default.Schema.Properties>,
    required: true,
  },
})

const { urlFor } = useSanityImage()
const { isSold, isUnderOffer, isLetAgreed } = useStatus()
</script>

<template>
  <div v-if="property" class="group aspect-h-1 aspect-w-1 overflow-hidden bg-gray-100 col-span-2 relative">
    <nuxt-img
      v-if="property.images && property.images"
      :src="urlFor(property.images[0].asset).width(500).height(500).fit('crop').url()"
      quality="85"
      :alt="property.images[0].alt"
      class="object-cover object-center group-hover:opacity-75"
      :width="500"
      :height="500"
      loading="lazy"
      sizes="sm:80vw md:400px lg:500px;"
      :class="{
        'opacity-60': isSold(property) || isUnderOffer(property) || isLetAgreed(property),
      }"
    />
    <LazyPropertyStatus
      v-if="property"
      :property="property"
    />
    <div class="absolute opacity-10 bg-black inset-0" />
    <div class="absolute bg-gradient-to-t from-primary-800 via-transparent to-transparent inset-0" />
    <div class="bg-transparent p-4 text-base flex flex-col justify-end">
      <UiBtn
        :to="`/property/${property.slug.current}`"
        class="mb-3 text-white"
        :padded="false"
        variant="link"
        color="white"
        size="xl"
      >
        <span class="absolute inset-0" aria-hidden="true" />
        <span class="sr-only">View property: {{ property.title }}</span>
      </UiBtn>

      <div class="flex justify-between items-end">
        <div>
          <p :to="`/property/${property.slug.current}`" class="line-clamp-1 mb-0">
            <span class="font-semibold text-gray-50 text-lg">{{ property.title }}</span>
          </p>
          <PropertyRooms
            :bedrooms="property.bedrooms ?? 0"
            :bathrooms="property.bathrooms ?? 0"
            :receptions="property.receptions ?? 0"
            :landarea="property.landarea ?? {}"
            dark
          />
        </div>
        <PropertyPrice
          v-if="property.price && property.database"
          :price="property.price"
          :database="property.database"
          dark
        />
      </div>
    </div>
  </div>
</template>
